import React from 'react';
import styled from 'styled-components';
import HorizontalSpacing from '../../../horizontal-spacing';
import {TopMobileSection} from './top-mobile-section';

const MobileSectionRoot = styled.div`
  background-color: ${({theme}) => theme.palette.common.turquoise};
  padding: 80px 0;

  ${({theme}) => theme.breakpoints.down('tablet')} {
    padding-top: 0;
  }
`;

export const MobileSection: React.FC = () =>
    <MobileSectionRoot>
        <HorizontalSpacing>
            <TopMobileSection/>
        </HorizontalSpacing>
    </MobileSectionRoot>;
