import Button from '../button';
import Typography from '../typography';

interface Props {
    onConfirm: () => void;
    onClose: () => void;
    open?: boolean;
}

const ConfirmCloseDialog = ({onConfirm, onClose, open = true}: Props) => {
    return <>
        {open
            ? <div
                className="fixed mobile:absolute w-full h-full bg-darkGrey bg-opacity-30 top-0 left-0 flex z-50 justify-center items-center rounded-xl">
                <div className="w-[80%] h-full p-3 mobile:p-7 flex items-center">
                    <div className="rounded-xl bg-lightTurquoise w-full h-fit p-6 flex flex-col gap-6">
                        <div className="flex flex-col gap-1">
                            <Typography variant="h3">Er du sikker på at du vil lukke dette vindue?</Typography>
                            <Typography className="mt-1">
                                Vær opmærksom på, at dette vindue ikke kan åbnes igen.
                            </Typography>
                        </div>
                        <div className="w-full flex justify-center gap-10">
                            <Button
                                variant={'outlined'}
                                onClick={onClose}
                            >
                                Fortryd
                            </Button>
                            <Button
                                onClick={onConfirm}
                                variant={'contained'}
                            >
                                OK
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            : <></>
        }
    </>;
};

export default ConfirmCloseDialog;
