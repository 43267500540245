import type {NextPage} from 'next';
import Head from 'next/head';
import React, {useEffect, useRef} from 'react';
import styled, {useTheme} from 'styled-components';
import HorizontalSpacing, {horizontalSpacingStyles} from '../components/layout/horizontal-spacing';
import {FixedOrderWizard} from '../components/layout/landing-page/fixed-order-wizard/fixed-order-wizard';
import {OrderWizard} from '../components/layout/landing-page/order-wizard/order-wizard';
import {Essentials} from '../components/layout/landing-page/sections/essentials';
import {GetStarted} from '../components/layout/landing-page/sections/get-started';
import {MobileSection} from '../components/layout/landing-page/sections/mobile-section/mobile-section';
import {ServicesSection} from '../components/layout/landing-page/sections/services';
import Typography from '../components/typography';
import {useInView} from 'framer-motion';
import useMediaQuery from '@crm/hooks/use-media-query/use-media-query';
import {ReasonsWhy} from '@crm/components/layout/landing-page/sections/reasons-why';
import LongMobileButton from '@crm/components/button/long-mobile-button';
import {routes} from '@crm/routes';
import {HappyHousekeepr} from "@crm/components/content/happy-housekeepr";
import Image from "next/legacy/image";
import {LargeImageSection} from "@crm/components/layout/sections/large-image-section";
import {images} from "@crm/utils/images";
import {useAppDispatch, useAppSelector} from "@crm/services/store";
import {windowCleaningActions} from "@crm/services/window-cleaning-slice";
import {ReviewCarousel} from "@crm/components/layout/landing-page/sections/review-carousel";

interface OrderWizardContainerProps {
    open?: boolean;
    $fixedWizardOpened: boolean;
}

const OrderWizardContainer = styled.div<OrderWizardContainerProps>`
    ${horizontalSpacingStyles};
    position: absolute;
    ${props => props.open && `top: 70px`};
    bottom: 15px;
    left: 0;
    right: 0;
    z-index: 5;

    ${({theme}) => theme.breakpoints.down('tablet')} {
        padding-left: 50px;
    }

    ${({theme}) => theme.breakpoints.down('tabletM')} {
        padding: 25px;
    }

    ${({theme}) => theme.breakpoints.down('mobile')} {
        bottom: 150px;
    }

    ${props => props.$fixedWizardOpened && `display: none`}
`;

const SplashCircle = styled.div`
    ${horizontalSpacingStyles};
    position: absolute;
    height: 130px;
    width: 130px;
    left: 480px;
    top: -70px;
    z-index: 6;

    background-color: #cc3f0c;
    border-radius: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    color: white;
    font-weight: bold;
    text-align: center;
    margin: 0;

    ${({theme}) => theme.breakpoints.down('tabletM')} {
        left: 460px;
        top: -50px;
    }

    ${({theme}) => theme.breakpoints.down('mobile')} {
        left: unset;
        right: 60px;
        top: -100px;
        padding: 1rem;
        height: 120px;
        width: 120px;
    }

    ${({theme}) => theme.breakpoints.down('mobileM')} {
        left: unset;
        right: 30px;
        top: -100px;
        padding: 1rem;
        height: 120px;
        width: 120px;
    }
`;


const Home: NextPage = () => {
    const dispatch = useAppDispatch();

    const [orderWizardOpened, setOrderWizardOpened] = React.useState(false);
    const [fixedWizardOpen, setFixedWizardOpen] = React.useState(false);
    const orderWizardContainerRef = useRef(null);

    const orderWizardInViewport = useInView(orderWizardContainerRef);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const completedWindowCleaning = useAppSelector(state => state.windowCleaning.completed);

    useEffect(() => {
        if (completedWindowCleaning) {
            dispatch(windowCleaningActions.resetState());
        }
    }, [completedWindowCleaning, dispatch]);

    const handleOpenFixedWizard = (open: boolean) => {
        setFixedWizardOpen(open);
        setOrderWizardOpened(open); //Will ensure the carousel is paused when opened.
    };

    return <>
        <Head>
            <title>Housekeepr | Kvalitets rengøring og vinduespudsning i Danmark</title>
            <link rel="canonical" href="https://housekeepr.dk"/>
            <meta name="description"
                  content="Housekeepr tilbyder professionel rengøring og vinduespudsning til hjemmet. Oplev vores bæredygtige, digitale og effektive løsninger til dit hjem i Danmark."/>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
        </Head>
        {!orderWizardInViewport && !isMobile &&
            <FixedOrderWizard WizardOpened={handleOpenFixedWizard}/>
        }

        <div id="bestil-og-beregn"
             className="relative w-full h-screen bg-right bg-small-woman-cleaning-in-front-of-shelf mobile:bg-woman-cleaning-in-front-of-shelf bg-cover mobile:bg-center"
        >
            <HorizontalSpacing>
                <Typography variant="h1" className="pt-[200px] mobile:pt-[220px]">
                    Professionel rengøring <br/>
                    og vinduespudsning
                </Typography>
            </HorizontalSpacing>

            <OrderWizardContainer
                ref={orderWizardContainerRef}
                open={!fixedWizardOpen && orderWizardOpened}
                $fixedWizardOpened={fixedWizardOpen}
            >
                {!fixedWizardOpen && !isMobile &&
                    <OrderWizard
                        addressLookupID="test-main-address"
                        onOpenChange={open => setOrderWizardOpened(open)}
                    />
                }
                {isMobile &&
                    <>
                        <SplashCircle>
                            <Typography variant="h4">
                                Bestil i dag. Spar op til 15%
                            </Typography>
                        </SplashCircle>
                        <div className="mobileM:pr-9">
                            <LongMobileButton
                                color={theme.palette.common.darkBlue}
                                onClick={() => window.open(routes.chooseServiceAddress.href, '_self')}
                            >
                                <Typography variant="h3">
                                    Beregn pris og bestil
                                </Typography>
                            </LongMobileButton>
                        </div>
                    </>
                }
            </OrderWizardContainer>
        </div>
        <ReasonsWhy/>
        <ReviewCarousel/>
        <GetStarted/>
        <div className="h-[90px] tablet:h-[130px] laptop:h-[150px] bg-gold w-full"></div>
        <MobileSection/>
        <div className="bg-gold text-darkBrown py-[60px] tabletM:py-[130px]">
            <LargeImageSection
                variant="imageLeft"
                imageSection={
                    <div className="flex justify-end">
                        <Image
                            src={images.MobileSection.WomanCleaning}
                            alt={images.MobileSection.alt.WomanCleaning}
                        />
                    </div>
                }
                textSection={
                    <HappyHousekeepr
                        bulletColor="darkBrown"
                        buttonBgColor={theme.palette.common.darkBrown}
                        buttonTextColor={theme.palette.common.gold}
                        $hoverVariant="whiteText"
                    />
                }
            />
        </div>
        <ServicesSection/>
        <Essentials/>
    </>;
};

export default Home;

