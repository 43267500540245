import Image from "next/legacy/image";
import React from 'react';
import styled, {useTheme} from 'styled-components';
import {
    CleaningBucket,
    CleaningCloth,
    CoffeeCapsules,
    Dishwasher,
    DishwashingLiquid,
    Handsoap,
    images,
    PaperTowel,
    Sponge,
    ToiletBrush,
    Toiletpaper,
    Toothbrushes,
    Toothpaste,
    VentedBrush
} from '../../../../utils/images';
import {TextIcon} from '../../../text-icon/text-icon';
import useMediaQuery from '@crm/hooks/use-media-query/use-media-query';
import typography from '@crm/components/typography';
import {LargeImageSection} from '@crm/components/layout/sections/large-image-section';
import {NewsletterSubscription} from '@crm/components/newsletter-subscription/newsletter-subscription';
import {NewsletterSubscriptionAbout} from "@crm/api/models/requests/housekeepr-api";

const EssentialsRoot = styled.div`
    background-color: ${({theme}) => theme.palette.common.gold};
    padding-top: 95px;
    padding-bottom: 95px;
`;

const TextContentContainer = styled.div`
    margin-bottom: 40px;
`;

const Headline = styled(typography)`
    margin-bottom: 20px;
    color: ${({theme}) => theme.palette.common.darkBrown};

    ${({theme}) => theme.breakpoints.down('mobile')} {
        font-size: 36px;
        text-align: center;
    }
`;

const BreadText = styled(typography)`
    color: ${({theme}) => theme.palette.common.darkBrown};
`;

export const Essentials = (props: { id?: string }) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('mobile'));

    const TextContent = () => <>
        <TextContentContainer>
            <Headline variant="h2" id={props.id}>
                Slut med at løbe tør…
            </Headline>
            <BreadText>
                Når du får rengøring hos Housekeepr kan du samtidig få leveret essentials til dit hjem, så
                du aldrig mere løber tør. Produkterne bestilles direkte i app’en, og kan leveres på
                abonnement eller som enkeltbestillinger. Alle produkter er naturligvis bæredygtige og i høj
                kvalitet - og levering sker i forbindelse med rengøringsbesøg, så vi både kan spare
                miljøet og give dig endnu bedre priser.
            </BreadText>
            <div className="grid grid-cols-2 mt-10 gap-3">
                <TextIcon icon={<Image src={Handsoap.src} layout="fill" alt="Bestil håndsæbe"/>}
                          vertical={mobile}>
                    Håndsæbe
                </TextIcon>
                <TextIcon icon={<Image src={DishwashingLiquid.src} layout="fill" alt="Bestil opvaskemiddel"/>}
                          vertical={mobile}>
                    Opvaskemiddel
                </TextIcon>
                <TextIcon icon={<Image src={Toiletpaper.src} layout="fill" alt="Bestil toiletpapir"/>}
                          vertical={mobile}>
                    Toiletpapir
                </TextIcon>
                <TextIcon icon={<Image src={PaperTowel.src} layout="fill" alt="Bestil køkkenrulle"/>}
                          vertical={mobile}>
                    Køkkenrulle
                </TextIcon>
                <TextIcon icon={<Image src={CleaningCloth.src} layout="fill" alt="Bestil klude"/>}
                          vertical={mobile}>
                    Klude
                </TextIcon>
                <TextIcon icon={<Image src={Sponge.src} layout="fill" alt="Bestil svampe"/>}
                          vertical={mobile}>
                    Svampe
                </TextIcon>
                {!mobile && <TextIcon icon={<Image src={VentedBrush.src} layout="fill" alt="Bestil opvaskebørste"/>}
                                      vertical={mobile}>
                    Opvaskebørste
                </TextIcon>}
                <TextIcon icon={<Image src={Dishwasher.src} layout="fill" alt="Bestil opvasketabs"/>}
                          vertical={mobile}>
                    Opvasketabs
                </TextIcon>
                {!mobile &&
                    /*This condition is weird but this is according to the design.*/
                    <TextIcon
                        vertical={mobile}
                        icon={
                            <Image
                                src={ToiletBrush.src}
                                layout="fill"
                                alt="Bestil toiletbørste"
                            />
                        }
                    >
                        Toiletbørste
                    </TextIcon>
                }{' '}
                <TextIcon icon={<Image src={Toothpaste.src} layout="fill" alt="Bestil tandpasta"/>}
                          vertical={mobile}>
                    Tandpasta
                </TextIcon>
                <TextIcon icon={<Image src={Toothbrushes.src} layout="fill" alt="Bestil tandbørste"/>}
                          vertical={mobile}>
                    Tandbørster
                </TextIcon>
                <TextIcon icon={<Image src={CoffeeCapsules.src} layout="fill" alt="Bestil kaffekapsler"/>}
                          vertical={mobile}>
                    Kaffe og the
                </TextIcon>
                <TextIcon icon={<Image src={CleaningBucket.src} layout="fill" alt="Bestil rengøringsspand"/>}
                          vertical={mobile}>
                    {mobile ? 'Prof.' : 'Professionelle'} rengøringsmidler
                </TextIcon>
                {mobile && <TextIcon icon={<Image src={VentedBrush.src} layout="fill" alt="Bestil opvaskebørste"/>}
                                     vertical={mobile}>
                    Opvaskebørste
                </TextIcon>}
            </div>
        </TextContentContainer>
        <div className="flex justify-center mobile:justify-start">
            <NewsletterSubscription newsAbout={NewsletterSubscriptionAbout.PRODUCTS}/>
        </div>
    </>;

    return <EssentialsRoot>
        <LargeImageSection
            variant="imageLeft"
            note={{text: 'KOMMER 2025', color: theme.palette.common.darkBrown}}
            textSection={<TextContent/>}
            imageSection={
                <Image
                    src={images.EssentialsSection.EssentialProducts}
                    className="rounded-full"
                    alt={images.EssentialsSection.alt.EssentialProducts}
                />
            }
        />
    </EssentialsRoot>;
};
