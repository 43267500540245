import React from "react";
import {ReviewCarouselSection} from "@crm/components/layout/sections/review-carousel-section";

const reviewElements = [
    {
        stars: 5,
        text: "Min hjælper er så god og gør hver gang et godt arbejde. Er så tilfreds og har den samme hver gang. Hun kender mit hus og mine ønsker.",
        date: "2024-03-01",
        name: "Nickie Willis",
    },
    {
        stars: 5,
        text: "Så god en oplevelse! Vi er så glade for vores nye housekeepr, virkelig god første gang 😊",
        date: "2024-01-11",
        name: "Marie",
    },
    {
        stars: 5,
        text: "Kommer helt ud i krogene, overalt.",
        date: "2024-03-08",
        name: "Morten Lyngholm",
    },
    {
        stars: 5,
        text: "I kan ikke gøre det bedre - vi er fuldt tilfreds - vi nyder at være i vores hjem, ikke noget vi selv skal gøre - vil til hver entusiasme anbefale jer til alle vi møder. Så taknemlige for det I gør for os.",
        date: "2024-03-05",
        name: "Grethe Jensen",
    },
    {
        stars: 5,
        text: "Super rengøring",
        date: "2024-03-14",
        name: "Karina",
    },
    {
        stars: 5,
        text: "Jeres medarbejder, der besøgte os i går, er meget grundig og dygtig. Vi er meget tilfredse.",
        date: "2023-08-12",
        name: "Kristine",
    },
    {
        stars: 5,
        text: "Flot rengøring",
        date: "2024-03-07",
        name: "Michael",
    },
    {
        stars: 5,
        text: "Vi er meget tilfredse med det rengøringsteam I gav os, efter min mand talte med jer. Vi nyder at komme hjem til et rent hjem 👍 Kh Jette",
        date: "2023-12-02",
        name: "Jette",
    },
    {
        stars: 5,
        text: "Hidtil bedste rengøring. TAK",
        date: "2024-01-22",
        name: "Lene",
    },
]

export const ReviewCarousel: React.FC = () =>
    <ReviewCarouselSection title="Hvad siger vores kunder om os?" reviewElements={reviewElements}/>;
