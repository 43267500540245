import styled from 'styled-components';
import Typography from '../typography';
import Image, {StaticImageData} from "next/legacy/image";
import React, {ReactNode} from 'react';
import {Arrow} from '@crm/utils/images';

interface Props {
    imageSrc: string | StaticImageData;
    imageAlt: string;
    title: ReactNode | ReactNode[];
    text: string;
    style?: React.CSSProperties;
    hover?: boolean;
    special?: boolean;
    note?: string;
}

const ContentItem = styled.div`
    flex: 1 0 33%;
    position: relative;
    text-align: center;
`;

const ItemTitle = styled(Typography)`
    font-size: 24px;

    ${({theme}) => theme.breakpoints.down('tablet')} {
        font-size: 24px;
    }

    ${({theme}) => theme.breakpoints.down('tabletM')} {
        font-size: 24px;
    }

    ${({theme}) => theme.breakpoints.down('mobile')} {
        font-size: 20px;
    }

    ${({theme}) => theme.breakpoints.down('mobileM')} {
        font-size: 15px;
    }

    color: ${({theme}) => theme.palette.common.darkGreen};
`;

const ImageContainer = styled.div`
    width: 180px;
    height: 180px;

    ${({theme}) => theme.breakpoints.down('laptop')} {
        width: 180px;
    }

    ${({theme}) => theme.breakpoints.down('tablet')} {
        width: 180px;
    }

    ${({theme}) => theme.breakpoints.down('tabletM')} {
        width: 150px;
        height: 150px;
    }

    ${({theme}) => theme.breakpoints.down('mobileM')} {
        width: 115px;
        height: 115px;
    }
`;

const Text = styled(Typography)`
    font-size: 13px;
`;

const CardImageForService: React.FC<Props> = (props: Props) => {
    const {imageSrc, imageAlt, title, text, style, hover, special, note} = props;
    return <ContentItem style={style} className="flex flex-col justify-center items-center">
        <div className="flex justify-center relative z-0 w-[180px]">
            {hover && !special && <div
                className="absolute top-0 left-0 w-full h-full rounded-full bg-darkGreen z-10 opacity-20"></div>}
            {special ? <ImageContainer>
                <div
                    className={`flex justify-center top-0 left-0 w-full h-full rounded-full ${
                        hover ? `bg-white` : `bg-darkGreen`
                    }`}
                >
                    <Arrow className={`${hover ? `fill-darkGreen` : `fill-lightGreen`} w-1/3`}/>
                </div>
            </ImageContainer> : <ImageContainer className='rounded-full overflow-hidden'>
                <Image src={imageSrc} layout="responsive" objectFit="inherit" alt={imageAlt}/>
            </ImageContainer>}
        </div>
        <ItemTitle variant="h3" className="my-3">{title}</ItemTitle>
        <Text>{text}</Text>
        {note && <div className="w-full flex justify-center">
            <div className="border-solid border-[1px] border-darkGreen rounded-xl w-[90px]">
                <Typography style={{fontSize: 8}}>
                    {note}
                </Typography>
            </div>
        </div>}
    </ContentItem>;
};

export default CardImageForService;
