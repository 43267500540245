import React from 'react';
import styled from 'styled-components';
import {IconBullet} from '../icon-bullet/icon-bullet';
import Typography from '../typography';

interface Props {
    icon: any;
    children: React.ReactNode | React.ReactNode[];
    vertical?: boolean;
}

const iconContainerStyles = {
    bulletContainer: 'width: 30px; height: 30px; justify-content: center; flex-shrink: 0;'
};

const BulletTypography = styled(Typography)<{ vertical?: boolean }>`
  font-size: 16px;
  ${props => props.vertical && `text-align: center; display: block;`}
`;

export const TextIcon: React.FC<Props> = (props: Props) => {
    const {icon, children, vertical} = props;

    return <>
        {vertical
            ? <div className=" flex flex-col items-center">
                <IconBullet bullet={icon} styles={iconContainerStyles} className="mb-1"/>
                <BulletTypography color="darkBrown" textAlign="center">
                    {children}
                </BulletTypography>
            </div>
            : <div>
                <IconBullet bullet={icon} styles={iconContainerStyles}>
                    <BulletTypography color="darkBrown" className='ml-4'>{children}</BulletTypography>
                </IconBullet>
            </div>
        }
    </>;
};
