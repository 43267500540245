import React, {HTMLAttributes} from 'react';
import styled, {DefaultTheme} from 'styled-components';
import {ThemeColorType, themeColorTypes} from '@crm/theme/light-theme';
import {Arrow} from '../../utils/images';
import {ColorTypes} from '../typography/typography';

interface Props extends HTMLAttributes<HTMLDivElement> {
    color?: ColorTypes;
    children?: React.ReactElement;
    bullet?: any;
    styles?: {
        root?: string;
        bulletContainer?: string;
    };
}

const alternativeColorTypes = [
    'initial',
    'inherit',
    'primary',
    'textPrimary',
    'textSecondary',
    'error'
] as const;

type AlternativeColorTypes = typeof alternativeColorTypes[number];

const getColor = (colorType: ColorTypes, theme: DefaultTheme) => {
    if (themeColorTypes.includes(colorType as ThemeColorType)) {
        return theme.palette.common[colorType as ThemeColorType];
    } else if (alternativeColorTypes.includes(colorType as AlternativeColorTypes)) {
        switch (colorType as AlternativeColorTypes) {
            case 'initial':
                return 'initial';
            case 'inherit':
                return 'inherit';
            case 'primary':
                return theme.palette.primary.main;
            case 'textPrimary':
                return theme.palette.text.primary;
            case 'textSecondary':
                return theme.palette.text.secondary;
            case 'error':
                return theme.palette.error.main;

            default:
                return 'initial';
        }
    }
};

interface BulletRootProps {
    styles?: string;
    color?: ColorTypes;
}

const BulletRoot = styled.div<BulletRootProps>`
  ${({color, theme}) => color && `color: ${getColor(color, theme)}`};

  ${({styles}) => styles && styles};
`;

interface ArrowWrapperProps {
    styles?: string;
}

const ArrowWrapper = styled.span<Props & ArrowWrapperProps>`
  display: inline-flex;
  align-items: center;
  width: 25px;
  height: 100%;
  position: relative;
  vertical-align: middle;

  & path {
    fill: ${({color, theme}) => color && getColor(color, theme)};
  }

  ${({styles}) => styles && styles};
`;

export const IconBullet: React.FC<Props> = (props: Props) => {
    const {children, color = 'primary', bullet = <Arrow/>, styles, ...rest} = props;

    return <BulletRoot className='flex flex-row items-center' color={color} styles={styles?.root} {...rest}>
        <ArrowWrapper styles={styles?.bulletContainer} color={color}>
            {bullet}
        </ArrowWrapper>
        {children}
    </BulletRoot>;
};
