// import Link from 'next/link';
import CardImageForService from '@crm/components/card-image/card-image-for-service';
import {StaticImageData} from "next/legacy/image";
import Link from 'next/link';
import React, {ReactNode, useState} from 'react';
import styled from 'styled-components';
import {Route, routes, servicesAnchorRoutes} from '@crm/routes';
import {images} from '@crm/utils/images';
import Typography from '../../../typography';
import HorizontalSpacing, {horizontalSpacingType} from '../../horizontal-spacing';

const ServicesSectionRoot = styled.div`
    text-align: center;
    background-color: ${({theme}) => theme.palette.common.lightGreen};
    color: ${({theme}) => theme.palette.common.darkGreen};
    padding: min(83px, 20%) 0;
`;

const Title = styled(Typography)`
    margin-bottom: 60px;
`;

interface ServiceLinkProps {
    image: StaticImageData;
    route: Route;
    title: ReactNode | ReactNode[];
    imageAlt: string;
    special?: boolean;
    note?: string;
}

const ServiceLink: React.FC<ServiceLinkProps> = (props: ServiceLinkProps) => {
    const {image, route, title, imageAlt, special, note} = props;
    const [hover, setHover] = useState<boolean>();
    return <div className="flex justify-center text-overflow">
        <Link href={route.href}>
            <div
                className="cursor-pointer"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <CardImageForService
                    imageSrc={image}
                    imageAlt={imageAlt}
                    title={title}
                    text=""
                    hover={hover}
                    special={special}
                    note={note}
                />
            </div>
        </Link>
    </div>;
};

export const ServicesSection: React.FC = () =>
    <ServicesSectionRoot>
        <HorizontalSpacing type={horizontalSpacingType.MOBILE_HORIZONTAL_SCROLL}>
            <Title variant="h2">Alle services i hjemmet. Ét sted</Title>
            <div className="grid grid-cols-2 gap-10 mobile:gap-y-11 mobile:gap-x-0 tablet:grid-cols-4">
                <ServiceLink
                    image={images.ServicesPage.Cleaning}
                    route={servicesAnchorRoutes.rengøring}
                    title="Rengøring"
                    imageAlt={images.ServicesPage.alt.Cleaning}
                />
                <ServiceLink
                    image={images.ServicesPage.WindowCleaning}
                    route={servicesAnchorRoutes.vinduespudsning}
                    title="Vinduespudsning"
                    imageAlt={images.ServicesPage.alt.WindowCleaning}
                />
                <ServiceLink
                    image={images.EssentialsSection.EssentialProducts}
                    route={servicesAnchorRoutes.produkterViaAbonnement}
                    title="Home Essentials"
                    imageAlt={images.EssentialsSection.alt.EssentialProducts}
                    note="KOMMER I 2025"
                />{' '}
                <ServiceLink
                    image={images.ServicesPage.CarpetCleaning}
                    route={servicesAnchorRoutes.tæpperens}
                    title="Tæpperens"
                    imageAlt={images.ServicesPage.alt.CarpetCleaning}
                    note="KOMMER I 2025"
                />{' '}
                <ServiceLink
                    image={images.ServicesPage.Laundry}
                    route={servicesAnchorRoutes.tøjrensOgVask}
                    title="Tøjrens/vask"
                    imageAlt={images.ServicesPage.alt.Laundry}
                    note="KOMMER I 2025"
                />{' '}
                <ServiceLink
                    image={images.Services.GræsHækHave}
                    route={servicesAnchorRoutes.græsHækHave}
                    title="Græs, hæk, have"
                    imageAlt={images.Services.alt.GræsHækHave}
                    note="KOMMER I 2025"
                />{' '}
                <ServiceLink
                    image={images.ServicesPage.HandymanServicesPage}
                    route={servicesAnchorRoutes.handyman}
                    title="Handyman"
                    imageAlt={images.ServicesPage.alt.HandymanServicesPage}
                    note="KOMMER I 2025"
                />{' '}
                <ServiceLink
                    image={images.Services.MoreServices}
                    title="Se alle services"
                    route={routes.services}
                    imageAlt={images.Services.alt.MoreService}
                    special
                />
            </div>
        </HorizontalSpacing>
    </ServicesSectionRoot>;
