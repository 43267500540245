import {images} from '@crm/utils/images';
import {CardsScrollSection} from '../../sections/cards-scroll-section';
import React from "react";

const sliderElements = [
    {
        imageSrc: images.ReasonsWhy.VacuumLivingRoom,
        imageAlt: images.ReasonsWhy.alt.VacuumLivingRoom,
        title: "Du får altid den samme housekeepr",
        text: "Rengøring bliver bedre i takt med at din Housekeepr lærer dit hjem at kende. Derfor får en fast Housekeepr som sætter en stolthed i at levere god service hver gang.",
    },
    {
        imageSrc: images.EnvironmentAndQualityPage.CarFromFront,
        imageAlt: images.EnvironmentAndQualityPage.alt.CarFromFront,
        title: `Bæredygtig og effektiv rengøring`,
        text: "Vi har bygget vores system til at sikre så lidt kørsel mellem de enkelte hjem som muligt. Derudover bruger vi kun plantebaserede rengøringsmidler, som er skånsomme for både dit hjem og miljøet.",
    },
    {
        imageSrc: images.ServicesPage.Cleaning,
        imageAlt: images.ServicesPage.alt.Cleaning,
        title: "Vi medbringer selv alt hvad vi skal bruge",
        text: "Når du bestiller rengøring hos Housekeepr gør vi det så nemt for dig som muligt. Vi har derfor alt med der skal bruges til at udføre rengøringen.",
    },
    {
        imageSrc: images.ReasonsWhy.MobileAppTwo,
        imageAlt: images.ReasonsWhy.alt.MobileAppTwo,
        title: "Skræddersyet service direkte i app'en",
        text: "Alle hjem er forskellige og rengøringsbehovet kan ændre sig fra uge til uge. Derfor kan du løbende skræddersy din service med få klik i Housekeepr app’en.",
    },
    {
        imageSrc: images.ReasonsWhy.ExtraStartup,
        imageAlt: images.ReasonsWhy.alt.VacuumLivingRoom,
        title: "Opstart med én ekstra time gratis",
        text: "Ved din første rengøring får du en ekstra time gratis, så der er tid til at få en god introduktion til dit hjem og mulighed for at hilse ordentligt på hinanden.",
    },
    {
        imageSrc: images.ReasonsWhy.MobileAppTwoZoomedIn,
        imageAlt: images.ReasonsWhy.alt.MobileAppTwoZoomedIn,
        title: "Housekeepr app gør det helt simpelt",
        text: "Med app'en kan du håndtere din rengøring med få klik og du har alt info samlet ét sted. Alt fra ugentlig skræddersyning af din rengøring til aflysninger eller flytninger af din rengøring.",
    },
    {
        imageSrc: images.ReasonsWhy.LaughingPeople,
        imageAlt: images.ReasonsWhy.alt.LaughingPeople,
        title: "Kundeservice sidder altid klar",
        text: "Uanset hvad du har brug for sidder vi klar til at hjælpe dig direkte i Housekeepr app'en."
    }
]

export const ReasonsWhy: React.FC = () =>
    <CardsScrollSection title="Gode grunde til at vælge rengøring fra Housekeepr" slideElements={sliderElements}
                        roundedImages/>;