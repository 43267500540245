import React from 'react';
import styled from 'styled-components';
import {getFluidSizing} from '@crm/utils/helpers';
import {Arrow} from '../../../../utils/images';
import FadeIn from '../../../animations/fade-in';
import HorizontalSpacing, {globalMobilePadding} from '../../horizontal-spacing';
import {OrderWizard} from '../order-wizard/order-wizard';

interface Props {
    WizardOpened?(open: boolean): any;
}

const Wrapper = styled.div`
    position: fixed;
    bottom: 30px;
    left: 0;
    z-index: 2;
    width: 100%;
`;

const FixedOrderWizardRoot = styled.div`
    padding: 12px 20px;
    background-color: ${({theme}) => theme.palette.common.turquoise};
    box-shadow: 0 0 35px #00000065;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 auto;

    max-width: 350px;

    & svg {
        width: 31px;

        & path {
            fill: ${({theme}) => theme.palette.common.darkBlue};
        }
    }
`;

const OrderWizardRoot = styled(FadeIn)<{ bottom: number }>`
    position: absolute;
    display: inline-block;
    padding-right: ${globalMobilePadding};
    bottom: ${props => props.bottom}px;
    -webkit-transition: bottom 0.3s ease-out;
    -moz-transition: bottom 0.3s ease-out;
    -o-transition: bottom 0.3s ease-out;
    transition: bottom 0.3s ease-out;
`;

const Text = styled.p`
    font-family: ${({theme}) => theme.typography.primary.fontFamily};
    font-weight: ${({theme}) => theme.typography.weight.light};
    font-size: ${({theme}) =>
    getFluidSizing(20, 26, theme.breakpoints.values.largeScreen, theme.breakpoints.values.mobile)};
    color: ${({theme}) => theme.palette.text.primary};
`;

const ModifiedArrow = styled(Arrow)`
    & path {
        fill: ${({theme}) => theme.palette.common.red} !important;
    }
`;
export const FixedOrderWizard = (props: Props) => {
    const OrderWizardRef = React.createRef<HTMLDivElement>();
    const FixedOrderWizardRootRef = React.createRef<HTMLDivElement>();

    const [orderWizardVisible, setOrderWizardVisible] = React.useState(false);
    const [orderWizardOpen, setOrderWizardOpen] = React.useState(false);
    const [orderWizardBottom, setOrderWizardBottom] = React.useState(0);
    const [addOrderWizardBottom, setAddOrderWizardBottom] = React.useState(0);

    let closeWizardTimeout: NodeJS.Timeout;

    const hideOrderWizard = () => setOrderWizardVisible(false);

    const handleOrderWizardOpen = (open: boolean) => {
        setOrderWizardOpen(open);
        props.WizardOpened && props.WizardOpened(open);

        !open && hideOrderWizard();
    };

    const handleOnListChange = (listHeight: number) => {
        const additionalBottom = listHeight > 0 ? listHeight - 37 : 0; //Subtract 37, because one list element has a height of 37px.
        setAddOrderWizardBottom(additionalBottom);
    };

    const updateWizardBottom = React.useCallback(() => {
        let bottom;
        if (OrderWizardRef.current && FixedOrderWizardRootRef.current) {
            bottom =
                OrderWizardRef.current.clientHeight +
                addOrderWizardBottom -
                FixedOrderWizardRootRef.current.clientHeight;
            setOrderWizardBottom(bottom);
        }
    }, [FixedOrderWizardRootRef, OrderWizardRef, addOrderWizardBottom]);

    const handleMouseEnterWizard = () => {
        setOrderWizardVisible(true);
        clearTimeout(closeWizardTimeout);
    };

    const handleMouseLeaveWizard = () => {
        closeWizardTimeout = setTimeout(() => !orderWizardOpen && hideOrderWizard(), 1500);
    };

    React.useEffect(() => updateWizardBottom(), [orderWizardVisible, addOrderWizardBottom, updateWizardBottom]);

    return <Wrapper>
        <FadeIn>
            <HorizontalSpacing>
                {orderWizardVisible &&
                    <OrderWizardRoot
                        onMouseLeave={handleMouseLeaveWizard}
                        onMouseEnter={handleMouseEnterWizard}
                        bottom={orderWizardBottom}
                    >
                        <OrderWizard
                            ref={OrderWizardRef}
                            fixedWizard={true}
                            onOpenChange={handleOrderWizardOpen}
                            onListChange={handleOnListChange}
                            onChooseServiceTypeDidMount={updateWizardBottom}
                        />
                    </OrderWizardRoot>
                }
                <FixedOrderWizardRoot onMouseOver={handleMouseEnterWizard} ref={FixedOrderWizardRootRef}>
                    <Text>Beregn pris og bestil</Text>
                    <ModifiedArrow/>
                </FixedOrderWizardRoot>
            </HorizontalSpacing>
        </FadeIn>
    </Wrapper>;
};
