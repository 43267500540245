import React from 'react';
import {useAppSelector} from "@crm/services/store";
import {stepperSelectors} from "@crm/services/stepper-slice";

interface Props {
    children: React.ReactNode | React.ReactNode[];
}

interface StepProps {
    index?: number;
    last?: boolean;
}

export const Step: React.FC<Props & StepProps> = props => {
    const {children, index} = props;
    const activeStep = useAppSelector(stepperSelectors.selectStep);

    if (activeStep === index) {
        return <>{children}</>;
    }

    return null;
};

export default Step;
