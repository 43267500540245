import FadeIn from '@crm/components/animations/fade-in';
import Typography from '@crm/components/typography';
import {Close} from '@crm/utils/images';
import {PropsWithChildren} from 'react';

const Container = (props: PropsWithChildren<{ mobile: boolean }>) => {
    const {children, mobile} = props;
    return <>
        {mobile ? <div className="bottom-[85px] flex justify-center w-full left-0">
            <div className="bg-lighterTurquoise shadow-xl rounded-lg flex flex-col">{children}</div>
        </div> : <div className="w-full bg-lightTurquoise shadow-xl rounded-lg bottom-[85px] flex flex-col">
            {children}
        </div>}
    </>;
};

const BbrErrorDialog = (props: { mobile?: boolean; address?: string; onClose?: () => void }) => {
    const {mobile = false, onClose} = props;

    return <FadeIn>
        <div className="pt-6 w-full">
            <Container mobile={mobile}>
                <div className="flex mt-5 mb-2 ml-5 mr-[0.95rem] justify-between items-center">
                    <Typography variant="body" fontWeight="bold" className="pt-0">
                        Noget gik galt
                    </Typography>
                    <button onClick={onClose}>
                        <Close className="fill-darkGreen hover:fill-white scale-[0.75]"/>
                    </button>
                </div>
                <Typography variant="body" className="p-5 pt-0 pb-5">
                    Prøv venligst igen senere.
                </Typography>
            </Container>
        </div>
    </FadeIn>;
};

export default BbrErrorDialog;
