import LinksToApp from '@crm/components/link/links-to-app';
import {MAX_WRAP_WIDTH} from '@crm/utils/constants';
import Image from 'next/image';
import React from 'react';
import styled, {useTheme} from 'styled-components';
import useMediaQuery from '../../../../../hooks/use-media-query/use-media-query';
import {images} from '@crm/utils/images';
import Typography from '../../../../typography';

const RightImageWrapper = styled.div`
    position: absolute;
    top: -${MAX_WRAP_WIDTH * 0.17}px;
`;

const MobileContainer = styled.div`
    flex: 8;
    position: relative;
    height: 100%;

    ${({theme}) => theme.breakpoints.down('tablet')} {
        width: 50%;
        display: inline-block;
    }
`;

const TopSection = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;

    ${({theme}) => theme.breakpoints.down('tablet')} {
        display: block;
        justify-content: center;
        align-content: center;
    }
`;

const TopSectionItem = styled.div`
    flex: 10;

    ${({theme}) => theme.breakpoints.down(1022)} {
        flex: 7;
    }

    ${({theme}) => theme.breakpoints.down('tablet')} {
        width: 100%;
    }
`;

const MobilesForMobileRoot = styled.div`
    position: relative;
    width: 100%;
    max-width: ${MAX_WRAP_WIDTH};
`;

const ListOfThings = styled.ul`
    ${({theme}) => theme.typography.body}
    & > li {
        padding-left: 15px;

        ${({theme}) => theme.breakpoints.between('tablet', 'desktop')} {
            padding-left: 0;
        }

        font-size: 15px;
        line-height: 24px;
    }
`;

const Title = styled(Typography)`
    padding-bottom: 25px;

    ${({theme}) => theme.breakpoints.down('tablet')} {
        text-align: center;
    }

    ${({theme}) => theme.breakpoints.down('laptop')} {
        font-size: 35px;
    }
`;

const MobilesForMobile = () => <MobilesForMobileRoot className='mb-10'>
    <MobileContainer className='absolute -top-32'>
        <Image
            src={images.MobileSection.RightPhone}
            alt={images.MobileSection.alt.RightPhone}
            className="drop-shadow-2xl"
        />
    </MobileContainer>
    <MobileContainer className=''>
        <Image
            src={images.MobileSection.LeftPhone}
            alt={images.MobileSection.alt.LeftPhone}
            className="drop-shadow-2xl"
        />
    </MobileContainer>
</MobilesForMobileRoot>;

export const TopMobileSection: React.FC = () => {
    const theme = useTheme();
    const isSmallerDevice = useMediaQuery(theme.breakpoints.down('tablet'));

    return <TopSection>
        {isSmallerDevice ? <MobilesForMobile/> : <MobileContainer className="absolute bottom-[50px]">
            <Image
                src={images.MobileSection.LeftPhone}
                alt={images.MobileSection.alt.LeftPhone}
                quality={100}
                className="drop-shadow-2xl"
            />
        </MobileContainer>}
        <TopSectionItem>
            <div className="flex flex-col justify-center content-center">
                <Title variant="h2">
                    Housekeepr app <br/> gør det helt simpelt
                </Title>
                <ListOfThings className="list-disc list-outside pl-5 w-fit self-center tablet:self-start">
                    <li>Skræddersy din rengøring eller vinduespudsning</li>
                    <li>Chat med kundeservice direkte i app</li>
                    <li>Se når vi er på vej, hvornår vi starter, og hvornår vi slutter.</li>
                    <li>Se og håndter dine betalinger</li>
                    <li>Flyt eller aflys dine besøg nemt med få klik</li>
                    <li>Tilføj og fjern ekstraydelser som ovnrens og afkalkning fra gang til gang
                    </li>
                    <li>
                        Bestil let andre servicetyper som vinduespudsning <br/> …og meget mere
                    </li>
                </ListOfThings>
                <div className="mt-5">
                    <LinksToApp/>
                </div>
            </div>
        </TopSectionItem>
        {!isSmallerDevice && <MobileContainer>
            <RightImageWrapper>
                <Image
                    src={images.MobileSection.RightPhone}
                    alt={images.MobileSection.alt.RightPhone}
                    quality={100}
                    className="drop-shadow-2xl"
                />
            </RightImageWrapper>
        </MobileContainer>}
    </TopSection>;
};
