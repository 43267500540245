import FadeIn from '@crm/components/animations/fade-in';
import Button from '@crm/components/button';
import Typography from '@crm/components/typography';
import React, {PropsWithChildren, useState} from 'react';
import styled, {useTheme} from 'styled-components';
import TextField from "@crm/components/text-field";
import Squared from "@crm/components/squared/squared";
import {DawaAddress} from "@crm/api/models/common/dawa-api";
import {useAppDispatch, useAppSelector} from "@crm/services/store";
import {wizardActions, wizardSelectors} from "@crm/services/wizard-slice";

const Container = (props: PropsWithChildren<{ mobile: boolean }>) => {
    const {children, mobile} = props;

    if (mobile) {
        return <div className="bottom-[85px] flex justify-center w-full left-0">
            <div className="bg-lighterTurquoise shadow-xl rounded-lg flex flex-col">
                {children}
            </div>
        </div>;
    }

    return <div className="w-[460px] bg-lightTurquoise shadow-xl rounded-lg bottom-[85px] flex flex-col">
        {children}
    </div>;
};

const InputContainer = styled.div`
    display: inline-block;
    width: 100%
`;

export const AreaDialog = (props: {
    mobile?: boolean,
    dawaAddress: DawaAddress,
    onConfirm: ({dawaAddress, area}: { dawaAddress: DawaAddress, area: number }) => void
}) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const {mobile = false} = props;
    const [area, setArea] = useState(0);
    const specifiedArea = useAppSelector(wizardSelectors.selectArea);
    const invalidArea = area ? area <= 0 : true;

    const clickConfirm = () => {
        if (invalidArea) {
            return;
        }
        dispatch(wizardActions.setArea(area));
        props.onConfirm({
            dawaAddress: props.dawaAddress,
            area: specifiedArea!,
        });
    };

    return <FadeIn>
        <div className="pt-6">
            <Container mobile={mobile}>
                <div className="flex mt-5 mb-2 ml-5 mr-[0.95rem] justify-between items-center">
                    <Typography variant="body" fontWeight="bold" className="pt-0">
                        Vi kunne desværre ikke finde dit boligareal hos BBR.
                    </Typography>
                </div>
                <Typography variant="body" className="px-5 pb-2 pt-1">
                    Indtast venligst dit fulde boligareal
                </Typography>
                <div style={{display: "flex", alignItems: "center", width: "140px", alignSelf: "center"}}
                     className="px-4">
                    <InputContainer>
                        <TextField
                            id="area"
                            value={area ? area.toString() : ''}
                            errorTooltip={invalidArea ? 'Boligarealet må ikke være 0' : ''}
                            onChange={event => {
                                const value = parseInt(event.target.value);
                                if (value && !isNaN(value)) {
                                    setArea(value);
                                }
                            }}
                            styles={{inputField: `background-color: ${theme.palette.common.lighterTurquoise}`}}
                            tooltipStyle={{
                                minWidth: 200,
                            }}
                        />
                    </InputContainer>
                    <Typography style={{marginLeft: 7}}>
                        <Squared symbol={"m"}/>
                    </Typography>
                </div>

                <Button
                    className="flex m-4"
                    disabled={invalidArea}
                    onClick={clickConfirm}
                    style={{
                        width: "30%",
                        alignSelf: "center",
                        backgroundColor: theme.palette.common.darkBlue,
                        opacity: invalidArea ? 0.5 : undefined,
                        color: theme.palette.common.lighterTurquoise
                    }}>
                    Videre
                </Button>
            </Container>
        </div>
    </FadeIn>
};
