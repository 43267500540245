import Typography from '@crm/components/typography';
import styled from 'styled-components';
import {motion, useInView} from 'framer-motion';
import React, {useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import tw from 'tailwind-styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';
import useXScrollListener from 'hooks/use-x-scroll-listener';
import HorizontalSpacing from '../horizontal-spacing';
import SafariFlexGap from '@crm/components/safari-components/safari-flex-gap';

export interface ReviewElements {
    stars: number;
    text: string;
    date: string;
    name: string;
    style?: React.CSSProperties;
}

interface Props {
    title: string;
    reviewElements: ReviewElements[];
}

const ChevronIcon = styled(FontAwesomeIcon)<{ $active: boolean }>`
    height: fit-content;
    width: 100%;

    &:hover {
        cursor: ${props => props.$active ? `pointer` : `normal`};
        color: ${props => props.$active ? `white` : undefined};
    }

    opacity: ${props => !props.$active ? `50%` : undefined};
`;

const ChevronContainer = tw.div`
  flex-auto
  items-center
  mobile:w-16
  flex
  absolute
  z-10
  bg-turquoise
  bg-opacity-50 
  h-full
  top-0
  px-5
  opacity-0
  mobile:opacity-100
  w-0
`;

export const ReviewCarouselSection = (props: Props) => {
    const {reviewElements, title} = props;

    const [scrollCursor, setScrollCursor] = useState<'start' | 'middle' | 'end'>('start');
    const [animationComplete, setAnimationComplete] = useState(false);

    const scrollContainerRef = useRef<HTMLElement>(null);
    const chevronRef = useRef<HTMLDivElement>(null);
    const chevronInView = useInView(chevronRef);
    const backgroundColor = 'bg-turquoise';
    const containerColor = 'bg-lighterTurquoise';
    const containerTextColor = 'text-darkGreen';

    const initialScroll = {
        x: '100vw',
        opacity: 1
    };

    const endScroll = {
        x: 0,
        opacity: 1
    };

    /**
     * Scrolls the elements inside the ScrollContainer.
     * @param scrollAmount The sign determines the direction. negative scrolls left and positive scrolls right.
     */
    const handleOnChevronClick = (scrollAmount = 300) => {
        const scrollContainer = scrollContainerRef.current;
        const maxScroll = scrollContainer && scrollContainer.scrollWidth - scrollContainer.offsetWidth;
        const minScroll = 0;
        const currentOffset = scrollContainer?.scrollLeft;

        let scrollToPos = 0;
        currentOffset != undefined && (scrollToPos = currentOffset + scrollAmount);

        if (scrollToPos <= minScroll) {
            scrollToPos = minScroll;
        }

        if (maxScroll && scrollToPos >= maxScroll) {
            scrollToPos = maxScroll;
        }
        scrollContainerRef.current?.scroll(scrollToPos, 0);
    };

    /** setScrollCursor to indicate whether the scrollContainer has been scrolled all the way to the start, somewhere in the middle or end. */
    const handleScrollCursor = () => {
        const scrollContainer = scrollContainerRef.current;
        const maxScroll =
            scrollContainer && scrollContainer.scrollWidth - scrollContainer.offsetWidth - 1;
        const minScroll = 0;
        const currentOffset = scrollContainer?.scrollLeft;
        if (maxScroll && currentOffset != undefined) {
            if (currentOffset <= minScroll) {
                setScrollCursor('start');
            } else if (currentOffset >= maxScroll) {
                setScrollCursor('end');
            } else {
                setScrollCursor('middle');
            }
        }
    };

    const scrollListener = useXScrollListener(scrollContainerRef?.current, handleScrollCursor);
    const noScroll = !scrollListener.getScrolling;

    return <div className={`w-full h-fit pb-20 ${backgroundColor}`}>
        <HorizontalSpacing>
            <Typography variant="h2" className="py-20 text-center">
                {title}
            </Typography>
        </HorizontalSpacing>
        <div className="relative">
            <ChevronContainer className={`left-0 ${noScroll && `mobile:w-0 mobile:opacity-0`}`}>
                <div
                    ref={chevronRef}
                >
                    <ChevronIcon
                        icon={faChevronLeft}
                        $active={scrollCursor != 'start'}
                        onClick={() => handleOnChevronClick(-396)}
                    />
                </div>
            </ChevronContainer>
            <ScrollContainer
                nativeMobileScroll
                innerRef={scrollContainerRef}
                className={`scrollContainerRef scroll-smooth overflow-x-auto flex snap-x snap-mandatory mobile:snap-none ${
                    noScroll ? `justify-center` : `pl-12 mobile:pl-24`
                } ${animationComplete && `w-full`} `}
            >
                <motion.div
                    initial={initialScroll}
                    animate={chevronInView && endScroll}
                    viewport={{once: true}}
                    transition={{delay: 0, duration: 2, type: 'spring'}}
                    onAnimationComplete={() => setAnimationComplete(true)}
                >
                    <SafariFlexGap className="flex gap-12 relative">
                        {reviewElements.map(element =>
                            <div
                                className={`flex flex-col justify-between w-[300px] rounded-xl pb-10 pt-8 px-6 text-center ${containerColor} ${containerTextColor}`}
                                style={{height: '220px', scrollSnapAlign: animationComplete ? 'center' : 'none'}}
                                key={element.name + element.date}
                            >
                                <div>
                                    <Typography variant="h5" style={{fontWeight: 500, fontSize: "18px"}}>
                                        {element.name}
                                    </Typography>
                                </div>
                                <div
                                    className="whitespace-pre-line text-center"
                                    style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 4,
                                        WebkitBoxOrient: 'vertical'
                                    }}
                                >
                                    <Typography style={{fontSize: '13px'}}>
                                        {element.text}
                                    </Typography>
                                </div>
                                <div className="flex justify-center">
                                    {[...Array(5)].map((_, i) => (
                                        <svg
                                            key={i}
                                            className={`h-5 w-5 ${i < element.stars ? 'text-yellow-400' : 'text-gray-300'}`}
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                d="M9.049 2.927c.396-.805 1.556-.805 1.952 0l1.837 3.746 4.144.603c.87.127 1.217 1.195.588 1.809l-3 2.92.708 4.125c.152.885-.731 1.554-1.488 1.13l-3.707-1.947-3.707 1.947c-.757.424-1.64-.245-1.488-1.13l.708-4.125-3-2.92c-.629-.614-.282-1.682.588-1.809l4.144-.603 1.837-3.746z"/>
                                        </svg>
                                    ))}
                                </div>
                            </div>
                        )}
                        {!noScroll && <div className="w-0 mobile:w-3 flex-none"></div>}
                    </SafariFlexGap>
                </motion.div>
            </ScrollContainer>
            <ChevronContainer className={`right-0 ${noScroll ? `mobile:w-0 mobile:opacity-0` : ``}`}>
                <ChevronIcon
                    icon={faChevronRight}
                    $active={scrollCursor != 'end'}
                    onClick={() => handleOnChevronClick(396)}
                />
            </ChevronContainer>
        </div>
    </div>;
};
