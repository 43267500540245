import Fade from '@crm/components/fade';
import Step from '@crm/components/step';
import StepDialog from '@crm/components/step-dialog';
import Stepper from '@crm/components/stepper';
import React, {ForwardedRef} from 'react';
import ChooseServiceType from './steps/choose-service-type/choose-service-type';
import AddressLookup from "@crm/components/layout/landing-page/order-wizard/steps/address-lookup/address-lookup";
import styled from "styled-components";

const WizardWrapper = styled.div``;

interface Props {
    onListChange?: (listHeight: number) => void;
    fixedWizard?: boolean;
    mobile?: boolean;
    addressLookupID?: string;

    onOpenChange?(open: boolean): any;

    onChooseServiceTypeDidMount?(): any;
}

export const OrderWizard = React.forwardRef(function OrderWizard(props: Props, ref: ForwardedRef<HTMLDivElement>) {
    return <WizardWrapper ref={ref}>
        <Stepper>
            <Step>
                <AddressLookup
                    opened={props.onOpenChange}
                    fixedWizard={props.fixedWizard}
                    mobile={props.mobile}
                    id={props.addressLookupID}
                />
            </Step>

            <Step>
                <StepDialog>
                    <Fade in={true} unmountOnExit>
                        <ChooseServiceType onDidMount={props.onChooseServiceTypeDidMount}/>
                    </Fade>
                </StepDialog>
            </Step>
        </Stepper>
    </WizardWrapper>;
});
