import {Close} from '@crm/utils/images';
import React, {useState} from 'react';
import styled from 'styled-components';
import IconButton from '../icon-button';
import ConfirmCloseDialog from './confirm-close-dialog';
import {wizardActions} from '@crm/services/wizard-slice';
import {useAppDispatch, useAppSelector} from '@crm/services/store';
import {useRouter} from 'next/router';
import {stepperSelectors} from "@crm/services/stepper-slice";

interface Props {
    children: React.ReactNode | React.ReactNode[];

    onClose?(step: number): any;
}

const CloseButton = styled(IconButton)`
    path {
        transition: fill 0.2s;
        fill: ${({theme}) => theme.palette.common.darkGreen};
    }

    &:hover {
        path {
            fill: #fff;
        }
    }
`;

const StepDialogRoot = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 30px;
    top: 0;
    width: 100%;
    min-height: 650px;
    max-width: 675px;
    background-color: ${({theme}) => theme.palette.common.turquoise};
    height: 90vh;

    ${({theme}) => theme.breakpoints.down('mobile')} {
        max-width: 100%;
        top: 30px;
        padding-top: 60px;
        height: 100vh;
    }

    box-shadow: 0 0 35px #00000065;
    border-radius: 10px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    margin-bottom: 10px;
`;

export const StepDialog: React.FC<Props> = props => {
    const dispatch = useAppDispatch();
    const router = useRouter();

    const {children, onClose} = props;

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const activeStep = useAppSelector(stepperSelectors.selectStep);
    const numberOfSteps = useAppSelector(stepperSelectors.selectNumberOfSteps);

    /**
     * Closes the step-dialog
     */
    const closeStepDialog = () => {
        dispatch(wizardActions.resetState());
        onClose?.(activeStep);
        // Reload the currently open page and override the locally cached version.
        // IFF URL has parameters

        router.replace('/').catch(console.error);
    };

    /**
     * Closes the step-dialog if it's not the last step (checkout).
     * If the step is the checkout step, Open a dialog to confirm closing the step-dialog.
     */
    const handleClose = () => {

        if (activeStep == numberOfSteps - 1) {
            setOpenConfirmDialog(true);
        } else {
            closeStepDialog();
        }
    };

    return <StepDialogRoot>
        <Header>
            <CloseButton onClick={handleClose}>
                <Close/>
            </CloseButton>
        </Header>
        {children}
        <ConfirmCloseDialog
            open={openConfirmDialog}
            onClose={() => setOpenConfirmDialog(false)}
            onConfirm={closeStepDialog}
        />
    </StepDialogRoot>;
};

export default StepDialog;
