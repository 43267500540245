import {routes} from '@crm/routes';
import {Arrow} from "@crm/utils/images";
import styled from 'styled-components';
import {Bullet} from '../bullet/bullet';
import {HoverVariant, StylizedButton} from '../button/stylized-button';
import Link from '../link';
import Typography from '../typography';
import {ColorTypes} from '../typography/typography';

interface Props {
    buttonBgColor?: string;
    buttonTextColor?: string;
    bulletColor?: ColorTypes;
    $hoverVariant?: HoverVariant;
}

const BottomSectionTextWrapperRoot = styled.div`
    & > li {
        padding-bottom: 10px;
    }
`;

const SectionTitle = styled(Typography)`
    margin-bottom: 20px;

    ${({theme}) => theme.breakpoints.down('tablet')} {
        text-align: center;
        margin-top: 30px;
    }
`;

const BulletParagraph = styled(Typography)`
    font-size: 18px;

    ${({theme}) => theme.breakpoints.down('mobile')} {
        font-size: 16px;
    }
`;

export const HappyHousekeepr = (props: Props) => {
    const {bulletColor = 'darkGreen', buttonBgColor, buttonTextColor, $hoverVariant: hoverVariant} = props;

    return <BottomSectionTextWrapperRoot>
        <SectionTitle variant="h2">Glade Housekeeprs <br/> = glade kunder</SectionTitle>
        <Typography style={{paddingBottom: 45}}>
            Vi lever af glade kunder - og glade kunder kommer kun gennem glade medarbejdere. Så når du
            er kunde hos Housekeepr kan du altid regne med at blive mødt af et smil, uanset om du
            snakker med din personlige Housekeepr eller vores dygtige kundeservice. Vilkårene er i orden,
            og vi arbejder hele tiden på at forbedre dem.
            <br/>
            <br/>
            Service- og rengøringsbranchen trænger i den grad til en oprydning. Og det er præcis den
            oprydning, som er en af vores helt store fokusområder.
        </Typography>
        <ul>
            <Bullet color={bulletColor} className="pb-3" bullet={<span className="w-[17px]"><Arrow/></span>}>
                <BulletParagraph variant="h3">
                    Alle medarbejdere uddannes i vores rengøringsprogram
                </BulletParagraph>
            </Bullet>
            <Bullet color={bulletColor} className="pb-3" bullet={<span className="w-[17px]"><Arrow/></span>}>
                <BulletParagraph variant="h3">
                    Der arbejdes kun med skånsomme rengøringsmidler
                </BulletParagraph>
            </Bullet>
            <Bullet color={bulletColor} className="pb-3" bullet={<span className="w-[17px]"><Arrow/></span>}>
                <BulletParagraph variant="h3">
                    Ratings og feedback fra kunder belønner faglig stolthed
                </BulletParagraph>
            </Bullet>
            <Bullet color={bulletColor} className="pb-3" bullet={<span className="w-[17px]"><Arrow/></span>}>
                <BulletParagraph variant="h3">
                    Ordentlige arbejdsforhold og god løn
                </BulletParagraph>
            </Bullet>
            <Bullet color={bulletColor} className="pb-3" bullet={<span className="w-[17px]"><Arrow/></span>}>
                <BulletParagraph variant="h3">
                    Alle er en del af et stærkt Housekeepr community
                </BulletParagraph>
            </Bullet>
        </ul>
        <div className="flex justify-center mobile:justify-start max-w-[80vw]">
            <Link href={routes.becomeHousekeepr.href} className="w-full">
                <StylizedButton
                    className="w-full"
                    $textColor={buttonTextColor}
                    $bgColor={buttonBgColor}
                    style={{marginTop: 40}}
                    $hoverVariant={hoverVariant}
                >
                    BLIV HOUSEKEEPR
                </StylizedButton>
            </Link>
        </div>
    </BottomSectionTextWrapperRoot>;
};
