import CardImage from '@crm/components/card-image/card-image';
import React from 'react';
import {images} from '@crm/utils/images';
import Typography from '../../../typography';
import HorizontalSpacing from '../../horizontal-spacing';

const contentClassNames = 'w-full mobile:basis-1/3 flex justify-center pb-14';

export const GetStarted = () =>
    <div className="w-full bg-gold text-darkBrown">
        <HorizontalSpacing>
            <Typography textAlign="center" variant="h2" className="pt-[120px] pb-20">
                Sådan kommer du i gang. Helt simpelt
            </Typography>
            <div
                className="flex flex-col tablet:flex-row justify-center gap-x-5 mobile:gap-x-10 overflow-scroll no-scrollbar"
            >
                <div className={contentClassNames}>
                    <CardImage
                        imageSrc={images.GetStarted.WebApp}
                        imageAlt={images.GetStarted.alt.WebApp}
                        title="Online prisberegner"
                        text={`Beregn pris på under 60 sekunder og bestil skræddersyet rengøring og andre services. Alt sammen når det passer dig.`}
                    />
                </div>
                <div className={contentClassNames}>
                    <CardImage
                        imageSrc={images.GetStarted.MobileApp}
                        imageAlt={images.GetStarted.alt.MobileApp}
                        title="Beskriv dit hjem i app’en"
                        text={`Hjælp os, med at hjælpe dig, gennem vores onboarding flow i app’en, hvor du på 2 minutter kan skræddersy dine services og klæde din private Housekeepr bedst mulig på.`}
                    />
                </div>
                <div className={contentClassNames}>
                    <CardImage
                        imageSrc={images.GetStarted.Keys}
                        imageAlt={images.GetStarted.alt.Keys}
                        title="Vælg et af vores nøglesystemer"
                        text={`De fleste af vores kunder foretrækker at udlevere en nøgle til deres Housekeepr. Andre vælger at opsætte en nøgleboks.`}
                    />
                </div>
            </div>
        </HorizontalSpacing>
    </div>;
