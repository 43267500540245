import AddressField from '@crm/components/address-look-up/address-field';
import Typography from '@crm/components/typography';
import React, {HTMLAttributes, useState} from 'react';
import styled from 'styled-components';
import {getWindowDimensions} from '@crm/utils/helpers';
import {InvalidAddressDialog} from './invalid-address-dialog';
import BbrErrorDialog from './bbr-error-dialog';
import {AreaDialog} from "@crm/components/layout/landing-page/order-wizard/steps/address-lookup/area-dialog";
import {horizontalSpacingStyles} from "@crm/components/layout/horizontal-spacing";
import {wizardActions, wizardSelectors} from "@crm/services/wizard-slice";
import {useAppDispatch, useAppSelector} from "@crm/services/store";
import {stepperActions} from "@crm/services/stepper-slice";
import {skipToken} from "@reduxjs/toolkit/query";
import {useRouter} from "next/router";
import {routes} from "@crm/routes";
import {housekeeprApi} from "@crm/api/housekeepr-api";

interface Props extends HTMLAttributes<HTMLDivElement> {
    fixedWizard?: boolean;
    mobile?: boolean;

    opened?(open: boolean): any;
}

const AddressLookupRoot = styled.div<{
    $marginBottom: number
}>`
    display: inline-block;
    background-color: ${({theme}) => theme.palette.common.turquoise};
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 0 35px #00000065;
    max-width: 675px;

    ${({theme}) => theme.breakpoints.down('mobile')} {
        padding: 15px;
        width: 90%;
        margin-left: 5%;
    }

    margin-bottom: ${props => props.$marginBottom}px;
`;

const DescriptionTypography = styled(Typography)`
    margin-top: min(16px, 6%);
    margin-bottom: min(30px, 2%);
`;

const SplashCircle = styled.div`
    ${horizontalSpacingStyles};
    position: absolute;
    height: 130px;
    width: 130px;
    left: 480px;
    top: -70px;
    z-index: 6;

    background-color: #cc3f0c;
    border-radius: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    color: white;
    font-weight: bold;
    text-align: center;
    margin: 0;

    ${({theme}) => theme.breakpoints.down('tabletM')} {
        left: 460px;
        top: -50px;
    }

    ${({theme}) => theme.breakpoints.down('mobile')} {
        left: unset;
        right: 60px;
        top: -100px;
        padding: 1rem;
        height: 120px;
        width: 120px;
    }

    ${({theme}) => theme.breakpoints.down('mobileM')} {
        left: unset;
        right: 30px;
        top: -100px;
        padding: 1rem;
        height: 120px;
        width: 120px;
    }
`;

const AddressLookup: React.FC<Props> = (props: Props) => {
    const dispatch = useAppDispatch();
    const router = useRouter();

    const {mobile, opened, ...rest} = props; // Destructure opened to filter it out

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [clickedAddress, setClickedAddress] = useState(false);

    const dawaAddress = useAppSelector(wizardSelectors.selectAddress);
    const area = useAppSelector(wizardSelectors.selectArea);

    const {
        data: bbrUnit,
        error: bbrError,
        isFetching: bbrLoading
    } = housekeeprApi.useGetBbrUnitQuery(dawaAddress ? dawaAddress.adresse.id : skipToken);

    const allowedPropertyCodes = ['110', '120', '130', '131', '132', '140', '190', '510', '520'];

    const bbrArea = bbrUnit?.find(unit => {
        return unit.enh020EnhedensAnvendelse && allowedPropertyCodes.includes(unit.enh020EnhedensAnvendelse) && unit.enh026EnhedensSamledeAreal;
    })?.enh026EnhedensSamledeAreal;
    const noArea = !!dawaAddress && !bbrArea && !bbrLoading;

    const {
        data: address,
        error: addressError
    } = housekeeprApi.useRegisterAddressQuery(dawaAddress && (!noArea || area) ? {
        darId: dawaAddress.adresse.id,
        area: area
    } : skipToken);

    const nextStep = React.useCallback(() => {
        if (!address || !dawaAddress) {
            return;
        }
        if (mobile) {
            router.replace(routes.chooseServiceType.href).catch(console.error);
        } else {
            dispatch(stepperActions.nextStep());
        }
    }, [address, dawaAddress, dispatch, mobile, router]);

    React.useEffect(() => {
        if (!router.isReady || !dawaAddress || !address || (noArea && !area) || !clickedAddress) {
            return;
        }

        localStorage.removeItem('payment_processed');
        nextStep();
    }, [address, area, clickedAddress, dawaAddress, nextStep, noArea, router.isReady]);

    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (mobile) {
        return <>
            <AddressField
                label={'Adresse'}
                mobile={true}
                hideOptions={!!bbrError || noArea || !!addressError}
                name={'address'}
                onClick={result => {
                    if (result) {
                        setClickedAddress(true);
                    }
                }}
            />
            {bbrError &&
                <BbrErrorDialog
                    onClose={() => dispatch(wizardActions.clearDawaAddress())}
                />
            }
            {dawaAddress && noArea &&
                <AreaDialog
                    mobile={true}
                    onConfirm={() => nextStep()}
                    dawaAddress={dawaAddress.adresse}
                />
            }
            {addressError &&
                <InvalidAddressDialog
                    address={address?.address_string}
                    mobile={true}
                    resetSignal={false}
                />
            }
        </>;
    }

    return <AddressLookupRoot {...rest} $marginBottom={0}>
        <SplashCircle>
            <Typography>
                Bestil i dag. Spar op til 15%
            </Typography>
        </SplashCircle>
        <Typography color="darkBlue" variant="h2">
            Beregn pris og bestil
        </Typography>
        <DescriptionTypography color="darkBlue">
            Indtast først din adresse, så finder vi de relevante oplysninger
        </DescriptionTypography>
        <AddressField
            label="Adresse"
            name={'address'}
            hideOptions={!!dawaAddress && (!!bbrError || noArea)}
            onClick={result => {
                if (result) {
                    setClickedAddress(true);
                }
            }}
        />
        {dawaAddress && bbrError &&
            <BbrErrorDialog
                onClose={() => dispatch(wizardActions.clearDawaAddress())}
            />
        }
        {dawaAddress && noArea &&
            <AreaDialog
                onConfirm={() => nextStep()}
                dawaAddress={dawaAddress.adresse}
            />
        }
        {dawaAddress && addressError &&
            <InvalidAddressDialog
                address={address?.address_string}
                resetSignal={false}
            />
        }
    </AddressLookupRoot>;
};

export default AddressLookup;
