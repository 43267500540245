import {DetailedHTMLProps, HTMLAttributes} from 'react';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
}

/**
 * @param className prop overrides the default className.
 * @returns
 */
const LinksToApp = ({...rest}: Props) => <div
        className='flex justify-center gap-5 mobile:gap-10 items-center' {...rest}>
        <a href="https://apps.apple.com/dk/app/housekeepr/id6443780238?itsct=apps_box_badge&amp;itscg=30200"
           style={{
               width: 150,
           }}
        >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/da-dk?size=250x83&amp;releaseDate=1666310400"
                alt="Download nu via Apple AppStore"
                style={{
                    width: '88%',
                    margin: '5%',
                }}
            />
        </a>
        <a
            href='https://play.google.com/store/apps/details?id=dk.housekeepr.customer&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
            style={{
                width: 150,
            }}
        >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
                alt='Download nu via GooglePlay'
                src='https://play.google.com/intl/en_us/badges/static/images/badges/da_badge_web_generic.png'
                style={{}}
            />
        </a>
    </div>
;

export default LinksToApp;
