import React, {ReactNode} from 'react';
import {useAppDispatch, useAppSelector} from "@crm/services/store";
import {stepperActions, stepperSelectors} from "@crm/services/stepper-slice";

interface Props {
    children: React.JSX.Element | React.JSX.Element[] | (false | React.JSX.Element)[];
}

export const Stepper: React.FC<Props> = (props: Props) => {
    const dispatch = useAppDispatch();
    const {children} = props;
    const childrenArray = React.Children.toArray(children).filter(Boolean);
    const arr: (React.ReactElement | Iterable<ReactNode> | React.ReactPortal)[] = [];

    childrenArray.map(child => {
        if (!React.isValidElement(child)) {
            throw Error('Child to stepper is not a valid element');
        }

        if (child.type === React.Fragment) {
            arr.push(child.props.children);
        } else {
            arr.push(child);
        }
    });

    const steps = arr.map((step, index) => {
        if (!React.isValidElement(step)) {
            throw Error('Child to stepper is not a valid element');
        }

        return React.cloneElement(step, {
            key: index,
            index,
            last: index + 1 === childrenArray.length,
            ...step.props
        });
    });

    const step = useAppSelector(stepperSelectors.selectStep);
    const numberOfSteps = useAppSelector(stepperSelectors.selectNumberOfSteps);

    React.useEffect(() => {
        if (step >= steps.length) {
            dispatch(stepperActions.setStep(0));
        }
        if (steps.length != numberOfSteps) {
            dispatch(stepperActions.setNumberOfSteps(steps.length));
        }
    }, [dispatch, numberOfSteps, step, steps.length]);

    return <>
        {steps}
    </>;
};

export default Stepper;
